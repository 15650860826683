<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>Preguntas frecuentes</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Preguntas frecuentes</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start FAQ Area -->
        <section id="faq" class="section">
            <div class="container">
                <div class="title-block mx-auto">
                    <div class="section_title mb-4 text-center">
                        <h2><span>Todo lo que deseas saber</span></h2>
                        <!-- <span class="scroll-title" data-enllax-ratio="2.5" data-enllax-type="foreground" data-enllax-direction="horizontal">Preguntas frecuentes</span> -->
                        <!-- <p class="section_subtitle mx-auto">Todo lo que deseas saber</p> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9 mx-auto">
                        <div id="accordion" class="faq-accordion-panel wow fadeInUp" data-wow-duration="1500ms">
                            <!-- <div class="question-card shadow-sm active">
                                <div class="card-header p-0" id="headingOne">
                                    <h4 class="mb-0">
                                        <button class="btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span>¿El Merengón Bávaro tiene domicilios?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Realizamos domicilios para los pedidos que requieren varios días de anticipación, como tortas, bloques y suspiros. 

                                            Para porciones de merengon por el momento no manejamos domicilios desde nuestro punto de venta (vereda Berlín, cañón del Combeima). Si lo deseas, puedes encontrar algunos de nuestros productos Merengón Bávaro® en los locales de la empresa Lunita Consentida en Ibagué. Allí reciben pedidos de domicilio al 3123896407.
                                        </p>
                                    </div>
                                </div>
                            </div> -->
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingOne">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span>¿El Merengón Bávaro tiene domicilios?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Realizamos domicilios para los pedidos que requieren varios días de anticipación, como tortas, bloques y suspiros.
                                        </p>
                                        <p>
                                            Para porciones de merengón por el momento no manejamos domicilios desde nuestro punto de venta (vereda Berlín, cañón del Combeima). Si lo deseas, puedes encontrar algunos de nuestros productos Merengón Bávaro® en el local de la empresa Lunita Consentida en Ibagué. Allí reciben pedidos de domicilio al 3123896407.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingTwo">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span>¿El Merengón Bávaro tiene punto de venta en la zona urbana de Ibagué?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Por el momento nos encontramos en el cañón del Combeima, vereda Berlín (zona rural). También puedes encontrar algunos de nuestros productos Merengón Bávaro® en el local de la empresa Lunita Consentida en Ibagué, barrio La Pola. Allí reciben pedidos de domicilio al 3123896407.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingThree">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span>¿Dónde se encuentra ubicado el Merengón Bávaro?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Puedes encontrar nuestro punto de venta en la vereda Berlín, cañón del Combeima, zona rural, a quince minutos del centro de Ibagué.  
                                        </p><br>
                                        <RouterLink to="/como-llegar" class="btn btn-hover btn-sm white-btn no-capitalize">Cómo llegar<i class="mdi mdi-arrow-right ml-2"></i></RouterLink>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingFour">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span>¿Venden helados y obleas en el Merengón Bávaro?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Por el momento no, aunque te ofrecemos gran variedad de merengones, merengadas, fresas y duraznos con crema, leche asada y mielmesabe.
                                        </p>
                                        <RouterLink to="/postres" class="btn btn-hover btn-sm white-btn no-capitalize">Te invitamos a conocer la variedad de postres que tenemos<i class="mdi mdi-arrow-right ml-2"></i></RouterLink>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingFive">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"><span>¿Son los mismos merengones del almacén Éxito?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Aunque compartimos el gusto por los dulces sabores en nuestros productos, somos empresas diferentes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingSix">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"><span>¿Puedo llevarme un Merengón Bávaro® a otra ciudad?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            ¡Por supuesto! Recuerda llevarlo congelado en una nevera portátil, con pilas refrigerantes o garantizando que conserve el frío.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingSeven">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"><span>¿Ofrecen franquicias?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Por el momento no ofrecemos franquicias. Puedes encontrar el sabor de Merengón Bávaro® en nuestro único punto de venta del cañón del Combeima y en la empresa Lunita Consentida en Ibagué, barrio La Pola.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingEigth">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseEigth" aria-expanded="false" aria-controls="collapseEigth"><span>¿Cómo me puedo comunicar con el Merengón Bávaro®?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseEigth" class="collapse" aria-labelledby="headingEigth" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Permítenos escucharte: nuestro número es 3185106016
                                        </p>
                                        <RouterLink to="/contacto#formulario_contacto" class="btn btn-hover btn-sm white-btn no-capitalize">o envíanos un mensaje<i class="mdi mdi-arrow-right ml-2"></i></RouterLink>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingNine">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine"><span>¿Reciben tarjetas?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Nuestros medios de pago son efectivo y transferencias a Nequi o Davivienda.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingTen">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen"><span>¿Qué horarios tiene el Merengón Bávaro?</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Te esperamos en estos horarios:
                                        </p>
                                        <ul>
                                            <li>Lunes a viernes de  1:00 p.m. a 7:30 p.m. </li>
                                            <li>Sábados, domingos y festivos de 10:00 a.m. a 7:30 p.m.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Sección te puede interesar -->
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title">
                            <h4>Te puede interesar</h4>
                            <li><router-link to="/contacto">Contacto</router-link></li>
                            <li><router-link to="/postres">Productos</router-link></li>
                            <li><router-link to="/historia">Nosotros</router-link></li>
                        </div><br>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Sección te puede interesar -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //ENLLAX JS
    import '../assets/js/jquery.enllax.min.js';
import { RouterLink } from 'vue-router';


    export default {
    name: 'contact',
    mounted() {
        this.init();
        window.scrollTo(0, 0);
    },
    methods: {
        init() {
            this.hidePreloader();
            this.initWowAnimation();
        },
        /*----PRELOADER-----*/
        hidePreloader() {
            var preloaderFadeOutTime = 500;
            var preloader = $('.spinner-wrapper');
            setTimeout(function () {
                preloader.fadeOut(preloaderFadeOutTime);
            }, 500);
        },
        /*----WOW ANIMATION-----*/
        initWowAnimation() {
            var length = $('.wow').length;
            if (length >= 1) {
                const wow = new WOW({
                    boxClass: 'wow',
                    animateClass: 'animated',
                    offset: 0,
                });
                wow.init();
            }
        },
    },
    components: { RouterLink }
}
</script>
<style>
    .no-capitalize {
        text-transform: none;
    }
</style>