<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Home Start-->
        <section class="home-section" id="home">
            <!-- Line Animations Effect-->
            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
            <!-- Circle Animations Effect-->
            <ul class="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div class="home-bg">
                <div class="welcome-text-area">
                    <div class="area-bg"></div>
                    <div class="welcome-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="welcome-text text-center text-shadow">
                                        <h1 class="wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1s">¿Sabor único o experiencia agradable?</h1><br>
                                    </div>
                                    <div v-if="screenWidth >= 750" class="welcome-text text-center">
                                        <h2 class="wow fadeInUp text-shadow" data-wow-delay="0.6s" data-wow-duration="1s">¡En Merengón Bávaro disfrutas las dos!</h2><br>
                                    </div>
                                </div>
                            </div>
                            <!-- <vue-carrusel/> -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="welcome-img d-flex justify-content-center align-items-center wow fadeInUp" data-wow-delay="1s" data-wow-duration="1.5s">
                                        <img v-if="screenWidth >= 750" src="assets/images/slider-img/casita-banner.jpg" class="img-fluid" alt="" width="90%">
                                        <img v-else src="assets/images/slider-img/casita-banner.jpg" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <a href="#aboutus" class="scroll-next"><img src="assets/images/down-arrow.png" class="img-fluid" alt="" /></a>
        </section>
        <!-- Home End -->
        <!-- Start About-Us -->
        <section class="section" id="aboutus">
            <div class="container">
                <div class="title-block mx-auto">
                    <div class="section_title mb-4 text-center">
                        <h2 v-if="screenWidth < 750"><span>¡En Merengón Bávaro disfrutas las dos!</span></h2>
                        <span class="scroll-title z-index-class" data-enllax-ratio="2.5" data-enllax-type="foreground" data-enllax-direction="horizontal">Merengón Bávaro</span>
                        <p class="section_subtitle mx-auto black-font">Aquí encontrarás una gran variedad de postres, bebidas y el sabor original de nuestro producto estrella: el merengón, junto al paisaje del cañón del Combeima.</p><br>
                        <p class="section_subtitle mx-auto black-font">Te esperamos en nuestro único punto de venta, ubicado en la vereda Berlín, cañón del Combeima, a pocos minutos de Ibagué, Colombia.</p>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12">
                        <div class="about-image-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                            <!-- <img src="assets/images/aboutus/about01.jpg" class="img-fluid" alt="" /> -->
                            <img src="assets/images/aboutus/merengon.png" class="img-fluid" alt="" />
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="about-text-block wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1.5s">
                            <div class="about-content">
                                <h4>
                                    Nuestros horarios  <br />de atención:
                                </h4>
                                <ul>
                                    <li>Lunes a viernes de 1:00 p.m. a 7:30 p.m </li>
                                    <li>Sábados, domingos y festivos de 10:00 a.m. a 7:30 p.m </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-12 col-sm-12">
                        <div class="about-text-block wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1.5s">
                            <div class="about-content">
                                <h4 class="text-center">Escoge tu Merengón</h4>
                                <vue-winwheel :segments="wheel_options" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12 order-md-2">
                        <div class="about-image-block wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1.5s">
                            <RouterLink to="/postres">
                                <img :src="'assets/images/aboutus/' + current_product_home.picture_value" class="img-fluid img-margin" alt="" width="80%" height="80%" />
                            </RouterLink>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 order-md-1">
                        <div class="about-text-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                            <div class="about-content">
                                <h4>
                                    {{ current_product_home.text_value }}
                                </h4>
                                <div class="text">{{ current_product_home.aditional_info }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12 order-md-1">
                        <div class="about-text-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                            <div class="about-content">
                                <router-link to="/momentos-bavaros">
                                    <h4>
                                        Momentos<br />Bávaros
                                    </h4>
                                    <div class="text">
                                        <b>Comparte tus mejores momentos en Merengón Bávaro</b><br><br>
                                    </div>
                                    <div class="text">Etiquétanos en Instagram @merengonbavaro  o Facebook @merengonbavaro91, con el hashtag  #UnMerengónCon #UnMerengonPara.</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 order-md-2">
                        <div class="about-image-block wow fadeInUp img-margin" data-wow-delay="0.4s" data-wow-duration="1.5s">
                            <vue-instagram v-bind:reel_id="'https://www.instagram.com/reel/' + instagram_reel.text_value + '/embed/captioned/'"></vue-instagram>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-10 mx-auto">
                        <div class="image-container wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                            <div class="video-wrapper">
                                <a class="popup-youtube" data-toggle="modal" data-target="#videomodal" href="javascript:void(0)">
                                    <img src="assets/images/video-frame.png" class="img-fluid" alt="">
                                    <span class="video-play-button">
                                        <span></span>
                                    </span>
                                </a>
                            </div> 
                        </div>
                    </div>
                    <div class="modal fade" id="videomodal" tabindex="-1" role="dialog" aria-labelledby="videomodal" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content transparent-modal">
                                <div class="modal-body p-0">
                                    <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <iframe src="https://www.youtube.com/embed/UB1O30fR-EE?controls=0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </section>
        <!-- End About-Us -->
        <!-- Start Features -->
        <!-- <section class="section bg-light" id="features">
            <ul class="circles fetures-circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div class="container">
                <div class="title-block mx-auto">
                    <div class="section_title mb-4 text-center">
                        <h2><span>App Features</span></h2>
                        <span class="scroll-title" data-enllax-ratio="2.5" data-enllax-type="foreground" data-enllax-direction="horizontal">App Features</span>
                        <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="app-feature-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                            <div class="steps-hover-thumb"></div>
                            <div class="features-info">
                                <div class="featured-icons">
                                    <i class="mdi mdi-desktop-mac-dashboard"></i>
                                </div>
                                <h3>Clean Design</h3>
                                <div class="text">Add, delete and move elements around on the front end of your website. No coding and no confusing back end options.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="app-feature-block wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="1.5s">
                            <div class="steps-hover-thumb"></div>
                            <div class="features-info">
                                <div class="featured-icons">
                                    <i class="mdi mdi-responsive"></i>
                                </div>
                                <h3>Responsive Design</h3>
                                <div class="text">Add, delete and move elements around on the front end of your website. No coding and no confusing back end options.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="app-feature-block wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="1.5s">
                            <div class="steps-hover-thumb"></div>
                            <div class="features-info">
                                <div class="featured-icons">
                                    <i class="mdi mdi-rocket"></i>
                                </div>
                                <h3>Fast Boot</h3>
                                <div class="text">Add, delete and move elements around on the front end of your website. No coding and no confusing back end options.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="app-feature-block wow fadeInUp" data-wow-delay="0.8s" data-wow-duration="1.5s">
                            <div class="steps-hover-thumb"></div>
                            <div class="features-info">
                                <div class="featured-icons">
                                    <i class="mdi mdi-chat-processing-outline"></i>
                                </div>
                                <h3>Chatting Service</h3>
                                <div class="text">Add, delete and move elements around on the front end of your website. No coding and no confusing back end options.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="app-feature-block wow fadeInUp" data-wow-delay="1s" data-wow-duration="1.5s">
                            <div class="steps-hover-thumb"></div>
                            <div class="features-info">
                                <div class="featured-icons">
                                    <i class="mdi mdi-chart-bar"></i>
                                </div>
                                <h3>Analysis</h3>
                                <div class="text">Add, delete and move elements around on the front end of your website. No coding and no confusing back end options.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="app-feature-block wow fadeInUp" data-wow-delay="1.2s" data-wow-duration="1.5s">
                            <div class="steps-hover-thumb"></div>
                            <div class="features-info">
                                <div class="featured-icons">
                                    <i class="mdi mdi-headphones-settings"></i>
                                </div>
                                <h3>Quick Support</h3>
                                <div class="text">Add, delete and move elements around on the front end of your website. No coding and no confusing back end options.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section" id="howitworks">
            <div class="container">
                <div class="title-block mx-auto">
                    <div class="section_title mb-4 text-center">
                        <h2><span>How Does It Works?</span></h2>
                        <span class="scroll-title" data-enllax-ratio="2.5" data-enllax-type="foreground" data-enllax-direction="horizontal">How Does It Works?</span>
                        <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
                    </div>
                </div>
                <div class="work-steps-process">
                    <div class="work-steps-box">
                        <div class="row align-items-center">
                            <div class="col-md-6 col-sm-12">
                                <div class="work-steps-detail d-flex align-items-start wow fadeInLeft" data-wow-delay="0.3s" data-wow-duration="1000ms">
                                    <div class="steps-icon">
                                        <i class="mdi mdi-file-document-edit-outline"></i>
                                    </div>
                                    <div class="steps-text">
                                        <h4>Design & Developing</h4>
                                        <p class="step-desc">There are many variations of passages of this available, but the majority have suffered alteration in some form, by injected humour,</p>
                                    </div>
                                </div>
                                <div class="work-steps-detail d-flex align-items-start wow fadeInLeft" data-wow-delay="0.6s" data-wow-duration="1000ms">
                                    <div class="steps-icon">
                                        <i class="mdi mdi-file-document-edit-outline"></i>
                                    </div>
                                    <div class="steps-text">
                                        <h4>Design & Developing</h4>
                                        <p class="step-desc">There are many variations of passages of this available, but the majority have suffered alteration in some form, by injected humour,</p>
                                    </div>
                                </div>
                                <div class="work-steps-detail d-flex align-items-start wow fadeInLeft" data-wow-delay="0.9s" data-wow-duration="1000ms">
                                    <div class="steps-icon">
                                        <i class="mdi mdi-file-document-edit-outline"></i>
                                    </div>
                                    <div class="steps-text">
                                        <h4>Design & Developing</h4>
                                        <p class="step-desc">There are many variations of passages of this available, but the majority have suffered alteration in some form, by injected humour,</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="work-steps-images wow fadeInRight" data-wow-delay="0.2" data-wow-duration="1000ms">
                                    <img src="assets/images/works/how-works.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="parallax parallax-box section">
            <div id="counter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-rocket"></i></div>
                                <h3 id="number1" class="number counter-value" data-count="12">0</h3>
                                <span></span>
                                <p>Projects done</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-account-group"></i></div>
                                <h3 id="number2" class="number counter-value" data-count="55">0</h3>
                                <span></span>
                                <p>Happy clients</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-coffee"></i></div>
                                <h3 id="number3" class="number counter-value" data-count="360">0</h3>
                                <span></span>
                                <p>Cups of coffee</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-trophy"></i></div>
                                <h3 id="number4" class="number counter-value" data-count="10">0</h3>
                                <span></span>
                                <p>Awards</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section bg-light" id="price">
            <div class="container">
                <div class="title-block mx-auto">
                    <div class="section_title mb-4 text-center">
                        <h2><span>Best Pricing Plan</span></h2>
                        <span class="scroll-title" data-enllax-ratio="2.5" data-enllax-type="foreground" data-enllax-direction="horizontal">Best Pricing Plan</span>
                        <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12">
                        <ul class="nav nav-tabs tabs-custom mb-4" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="monthly-tab" data-toggle="tab" href="#monthly" role="tab" aria-controls="monthly" aria-selected="true">Monthly</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="yearly-tab" data-toggle="tab" href="#yearly" role="tab" aria-controls="yearly" aria-selected="false">Yearly</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="monthly" role="tabpanel" aria-labelledby="monthly-tab">
                                <div class="row justify-content-center">
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="price-box">
                                            <div class="p-box-inner">
                                                <div class="hover-line">
                                                    <div class="price-box-header">
                                                        <div class="plan-icons">
                                                            <i class="mdi mdi-rocket"></i>
                                                        </div>
                                                        <div class="price-title">Regular</div>
                                                    </div>
                                                    <div class="try-now-btn">
                                                        <a href="#" class="btn btn-hover border-btn">Try Now</a>
                                                    </div>
                                                    <ul class="package-list-data">
                                                        <li>2gb Hosting</li>
                                                        <li>10 Custom Domain</li>
                                                        <li>SSD Hosting</li>
                                                        <li>SSD Hosting</li>
                                                        <li>50gb Bandwith</li>
                                                        <li>Free Support</li>
                                                    </ul>
                                                    <h2><em>$</em>10.00</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="price-box">
                                            <div class="p-box-inner">
                                                <div class="hover-line">
                                                    <div class="price-box-header">
                                                        <div class="plan-icons">
                                                            <i class="mdi mdi-rocket"></i>
                                                        </div>
                                                        <div class="price-title">Standard</div>
                                                    </div>
                                                    <div class="try-now-btn">
                                                        <a href="#" class="btn btn-hover border-btn">Try Now</a>
                                                    </div>
                                                    <ul class="package-list-data">
                                                        <li>2gb Hosting</li>
                                                        <li>10 Custom Domain</li>
                                                        <li>SSD Hosting</li>
                                                        <li>SSD Hosting</li>
                                                        <li>50gb Bandwith</li>
                                                        <li>Free Support</li>
                                                    </ul>
                                                    <h2><em>$</em>20.00</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                                        <div class="price-box">
                                            <div class="p-box-inner">
                                                <div class="hover-line">
                                                    <div class="price-box-header">
                                                        <div class="plan-icons">
                                                            <i class="mdi mdi-rocket"></i>
                                                        </div>
                                                        <div class="price-title">Premium</div>
                                                    </div>
                                                    <div class="try-now-btn">
                                                        <a href="#" class="btn btn-hover border-btn">Try Now</a>
                                                    </div>
                                                    <ul class="package-list-data">
                                                        <li>2gb Hosting</li>
                                                        <li>10 Custom Domain</li>
                                                        <li>SSD Hosting</li>
                                                        <li>SSD Hosting</li>
                                                        <li>50gb Bandwith</li>
                                                        <li>Free Support</li>
                                                    </ul>
                                                    <h2><em>$</em>40.00</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="yearly" role="tabpanel" aria-labelledby="yearly-tab">
                                <div class="row justify-content-center">
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="price-box">
                                            <div class="p-box-inner">
                                                <div class="hover-line">
                                                    <div class="price-box-header">
                                                        <div class="plan-icons">
                                                            <i class="mdi mdi-rocket"></i>
                                                        </div>
                                                        <div class="price-title">Regular</div>
                                                    </div>
                                                    <div class="try-now-btn">
                                                        <a href="#" class="btn btn-hover border-btn">Try Now</a>
                                                    </div>
                                                    <ul class="package-list-data">
                                                        <li>2gb Hosting</li>
                                                        <li>10 Custom Domain</li>
                                                        <li>SSD Hosting</li>
                                                        <li>SSD Hosting</li>
                                                        <li>50gb Bandwith</li>
                                                        <li>Free Support</li>
                                                    </ul>
                                                    <h2><em>$</em>10.00</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="price-box">
                                            <div class="p-box-inner">
                                                <div class="hover-line">
                                                    <div class="price-box-header">
                                                        <div class="plan-icons">
                                                            <i class="mdi mdi-rocket"></i>
                                                        </div>
                                                        <div class="price-title">Standard</div>
                                                    </div>
                                                    <div class="try-now-btn">
                                                        <a href="#" class="btn btn-hover border-btn">Try Now</a>
                                                    </div>
                                                    <ul class="package-list-data">
                                                        <li>2gb Hosting</li>
                                                        <li>10 Custom Domain</li>
                                                        <li>SSD Hosting</li>
                                                        <li>SSD Hosting</li>
                                                        <li>50gb Bandwith</li>
                                                        <li>Free Support</li>
                                                    </ul>
                                                    <h2><em>$</em>20.00</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                                        <div class="price-box">
                                            <div class="p-box-inner">
                                                <div class="hover-line">
                                                    <div class="price-box-header">
                                                        <div class="plan-icons">
                                                            <i class="mdi mdi-rocket"></i>
                                                        </div>
                                                        <div class="price-title">Premium</div>
                                                    </div>
                                                    <div class="try-now-btn">
                                                        <a href="#" class="btn btn-hover border-btn">Try Now</a>
                                                    </div>
                                                    <ul class="package-list-data">
                                                        <li>2gb Hosting</li>
                                                        <li>10 Custom Domain</li>
                                                        <li>SSD Hosting</li>
                                                        <li>SSD Hosting</li>
                                                        <li>50gb Bandwith</li>
                                                        <li>Free Support</li>
                                                    </ul>
                                                    <h2><em>$</em>40.00</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="faq" class="section">
            <div class="container">
                <div class="title-block mx-auto">
                    <div class="section_title mb-4 text-center">
                        <h2><span>Frequently Asked</span></h2>
                        <span class="scroll-title" data-enllax-ratio="2.5" data-enllax-type="foreground" data-enllax-direction="horizontal">Frequently Asked</span>
                        <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9 mx-auto">
                        <div id="accordion" class="faq-accordion-panel wow fadeInUp" data-wow-duration="1500ms">
                            <div class="question-card shadow-sm active">
                                <div class="card-header p-0" id="headingOne">
                                    <h4 class="mb-0">
                                        <button class="btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span>Made With Love</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingTwo">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span>Free of Use</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingThree">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span>Fully Support Avaliable</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat. this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="question-card shadow-sm">
                                <div class="card-header p-0" id="headingFour">
                                    <h4 class="mb-0">
                                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span>Flat and Modern Ui & Ux</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                                    </h4>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat. this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section class="section client-Testimonials bg-light" id="client">
            <div class="container">
                <div class="title-block mx-auto">
                    <div class="section_title mb-4 text-center">
                        <h2><span>Client's Says</span></h2>
                        <span class="scroll-title" data-enllax-ratio="2.5" data-enllax-type="foreground" data-enllax-direction="horizontal">Client's Says</span>
                        <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="owl-carousel owl-theme client-slider wow fadeIn" data-wow-delay="0.3s" data-wow-duration="1000ms">
                            <div class="item">
                                <div class="client">
                                    <div class="client-image">
                                        <img src="assets/images/testi/client01.jpg" class="img-fluid" alt="">
                                    </div>
                                    <p class="client-say">
                                        Contrary to popular belief, this is not simply random
                                        text. It has roots in a piece of classical Latin literature from 45 BC, making it
                                        over 2000 years old.Contrary to popular belief, this is not simply random
                                        text.
                                    </p>
                                    <h4 class="client-name">
                                        <a href="#">
                                            Jack Jordan
                                        </a>
                                    </h4>
                                    <h5 class="designation">Lead Developer</h5>
                                </div>
                            </div>
                            <div class="item">
                                <div class="client">
                                    <div class="client-image">
                                        <img src="assets/images/testi/client01.jpg" class="img-fluid" alt="">
                                    </div>
                                    <p class="client-say">
                                        Contrary to popular belief, this is not simply random
                                        text. It has roots in a piece of classical Latin literature from 45 BC, making it
                                        over 2000 years old.Contrary to popular belief, this is not simply random
                                        text.
                                    </p>
                                    <h4 class="client-name">
                                        <a href="#">
                                            Jack Jordan
                                        </a>
                                    </h4>
                                    <h5 class="designation">Lead Developer</h5>
                                </div>
                            </div>
                            <div class="item">
                                <div class="client">
                                    <div class="client-image">
                                        <img src="assets/images/testi/client01.jpg" class="img-fluid" alt="">
                                    </div>
                                    <p class="client-say">
                                        Contrary to popular belief, this is not simply random
                                        text. It has roots in a piece of classical Latin literature from 45 BC, making it
                                        over 2000 years old.Contrary to popular belief, this is not simply random
                                        text.
                                    </p>
                                    <h4 class="client-name">
                                        <a href="#">
                                            Jack Jordan
                                        </a>
                                    </h4>
                                    <h5 class="designation">Lead Developer</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!-- End Testimonial -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //ENLLAX JS
    import '../assets/js/jquery.enllax.min.js';
    //TYPED JS
    import '../assets/js/typed.min.js';
    //OWL CAROUSEL JS
    import 'owl.carousel/dist/assets/owl.carousel.css';
    import 'owl.carousel/dist/assets/owl.theme.default.css';
    import 'owl.carousel';

    import VueWinwheel from "../components/adicionales/Ruleta.vue"
    import VueInstagram from "../components/adicionales/Instagram.vue"
    import VueCarrusel from "../components/adicionales/Carrusel.vue"
import { RouterLink } from 'vue-router';


    export default {
        name: 'index', mounted() {
            this.init();
            window.scrollTo(0, 0);
            window.addEventListener('resize', this.updateScreenSize)
        },
        data () {
                return {
                    wheel_options:[
                            {
                                textFillStyle: '#000',
                                fillStyle: '#9bb43c',
                                text:'Fresa',
                                picture: 'merengón_de_fresa'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#f26529',
                                text:'Durazno',
                                picture: 'merengón_de_durazno'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#9bb43c',
                                text:'Guanábana',
                                picture: 'merengón_de_guanábana'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#f26529',
                                text:'Tropical',
                                picture: 'merengón_tropical'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#9bb43c',
                                text:'Café',
                                picture: 'merengón_de_café'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#f26529',
                                text:'Limón',
                                picture: 'merengón_de_limón'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#9bb43c',
                                text:'Maracuyá',
                                picture: 'merengón_de_maracuyá'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#f26529',
                                text:'Frutos rojos',
                                picture: 'merengón_de_frutos_rojos'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#9bb43c',
                                text:'Nutella',
                                picture: 'merengón_de_nutella'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#f26529',
                                text:'Coco',
                                picture: 'merengón_de_coco'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#9bb43c',
                                text:'Aguacate',
                                picture: 'merengón_de_aguacate'
                            },
                            {
                                textFillStyle: '#000',
                                fillStyle: '#f26529',
                                text:'Frutos ácidos',
                                picture: 'merengón_de_frutos_ácidos'
                            }
                    ],
                    dynamic_data: [],
                    instagram_reel: '',
                    current_product_home: '',
                    screenWidth: window.innerWidth
                }
            },
        beforeCreate() {
            this.$store.dispatch('retrieveDynamicData')
                .then(data => {
                    this.dynamic_data = data;
                    this.instagram_reel = this.findItemByName('home_instagram_reel',this.dynamic_data);
                    this.current_product_home = this.findItemByName('current_product_home',this.dynamic_data);
                    console.log(this.current_product_home);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        components: {
            VueWinwheel,
            VueInstagram,
            VueCarrusel
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateScreenSize)
        },
        methods: {
            init() {
                this.hidePreloader();
                this.initWowAnimation();
                this.initEnllax();
                // this.initTyping();
                this.initClientSlider();
                this.initAccordian();
                this.windowScroll();
            },
            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
            /*----ONSCROLL JS-----*/
            windowScroll() {
                var self = this;
                $(window).on("scroll", function () {
                    self.initCounter();
                });
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            /*----ENLLAX SCROLLING-----*/
            initEnllax() {
                $('.scroll-title').enllax();
            },
            /*----ACCORDIAN-----*/
            initAccordian() {
                $(".question-card").on('click', function () {
                    if ($(this).hasClass('active')) {
                        $('.question-card').removeClass('active');
                        $(this).removeClass('active')
                    } else {
                        $('.question-card').removeClass('active');
                        $(this).addClass('active')
                    }
                });
            },
            /*----COUNTER-----*/
            initCounter() {
                var length = $('#counter').length;
                if (length >= 1) {
                    var a = 0;
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (a == 0 && $(window).scrollTop() > oTop) {
                        $('.counter-value').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            }, {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                }
                            });
                        });
                        a = 1;
                    }
                }
            },
            /*----TESTIMONIAL SLIDER-----*/
            initClientSlider() {
                var testimonialCarousel = $('.client-slider');
                if (testimonialCarousel.length > 0) {
                    testimonialCarousel.owlCarousel({
                        margin: 0,
                        loop: false,
                        nav: true,
                        center: false,
                        dots: false,
                        autoplay: true,
                        autoplayTimeout: 5000,
                        items: 3,
                        responsiveClass: true,
                        navText: ['<span class="mdi mdi-arrow-left"></span>', '<span class="mdi mdi-arrow-right"></span>'],
                        responsive: {
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 2,
                            },
                            1000: {
                                items: 2,
                            }
                        }
                    });
                }
            },
            /*----TYPED JS-----*/
            initTyping() {
                $(".typed").typed({
                    strings: ["Improve your Business."],
                    stringsElement: null,
                    typeSpeed: 30,
                    startDelay: 1200,
                    backSpeed: 20,
                    backDelay: 500,
                    loop: true,
                    loopCount: 5,
                    showCursor: false,
                    cursorChar: "|",
                    attr: null,
                    contentType: 'html',
                    callback: function () { },
                    preStringTyped: function () { },
                    onStringTyped: function () { },
                    resetCallback: function () { }
                });
            },
            findItemByName(name,items) {
                const item = items.find(item => item.name === name);
                return item;
            },
            updateScreenSize() {
                this.screenWidth = window.innerWidth
            }
        }
    }
</script>
<style scoped>
    .picture-of-wheel {
        padding-right: 100px;
        padding-left: 100px;
        padding-bottom: 100px;
    }
    .black-font {
        color: #000000 !important;
    }
    .img-margin {
        margin-left: 45px !important
    }
    .text-shadow {
        color: white;
        text-shadow: 2px 2px 4px #000000;
    }
    .z-index-class {
        z-index: -1;
    }
</style>