var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('footer',{staticClass:"footer theme-bg overflow-hidden"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-lg-12"},[_c('ul',{staticClass:"footer_menu_list list-unstyled mb-0"},[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Inicio")])],1),_c('li',[_c('router-link',{attrs:{"to":"/contacto"}},[_vm._v("Contacto")])],1),_c('li',[_c('router-link',{attrs:{"to":"/historia"}},[_vm._v("Nosotros")])],1),_vm._m(2)])]),_vm._m(3),_vm._m(4)])])]),_c('vue-floating'),_vm._m(5)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"circles"},[_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"footer-top"},[_c('div',{staticClass:"foot_logo d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"img-fluid d-block",attrs:{"src":"assets/images/logo.png","alt":""}})]),_c('p',{staticClass:"ftr-about"},[_vm._v("km 5.5 vereda Berlín, cañón del Combeima.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/documentos/Política de tratamiento de datos Merengon Bavaro.pdf","target":"_blank"}},[_vm._v("Política de datos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"copyrights"},[_c('p',{staticClass:"mb-0 text-center"},[_vm._v("2025 © "),_c('span',{staticClass:"text-white font-weight-bold"},[_vm._v("Merengón Bávaro®")])]),_c('p',{staticClass:"mb-0 text-center"},[_vm._v("Desarrollado por: "),_c('span',{staticClass:"text-white font-weight-bold"},[_vm._v("C & C")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('a',{staticClass:"social-icons",attrs:{"href":"https://www.facebook.com/merengonbavaro91/","target":"_blank"}},[_c('i',{staticClass:"mdi mdi-facebook-box"})]),_c('a',{staticClass:"social-icons",attrs:{"href":"https://www.instagram.com/merengonbavaro","target":"_blank"}},[_c('i',{staticClass:"mdi mdi-instagram"})]),_c('a',{staticClass:"social-icons",attrs:{"href":"https://wa.me/+573185106016","target":"_blank"}},[_c('i',{staticClass:"mdi mdi-whatsapp"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"back_top",attrs:{"href":"#"}},[_c('i',{staticClass:"mdi mdi-chevron-up"})])
}]

export { render, staticRenderFns }