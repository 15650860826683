<template>
    <div>
        <!-- Start Footer -->
        <footer class="footer theme-bg overflow-hidden">
            <!-- Circle Animations Effect-->
            <ul class="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-top">
                            <div class="foot_logo d-flex align-items-center justify-content-center">
                                <img src="assets/images/logo.png" class="img-fluid d-block" alt="">
                            </div>
                            <p class="ftr-about">km 5.5  vereda Berlín, cañón del Combeima.</p>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <ul class="footer_menu_list list-unstyled mb-0">
                            <li><router-link to="/">Inicio</router-link></li>
                            <li><router-link to="/contacto">Contacto</router-link></li>
                            <li><router-link to="/historia">Nosotros</router-link></li>
                            <li><a href="/documentos/Política de tratamiento de datos Merengon Bavaro.pdf" target="_blank">Política de datos</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-12">
                        <div class="copyrights">
                            <!-- <p class="mb-0 text-center">2024 &copy; <span class="text-white font-weight-bold">Merengón Bávaro® - </span> Página en construcción.</p> -->
                            <p class="mb-0 text-center">2025 &copy; <span class="text-white font-weight-bold">Merengón Bávaro®</span></p>
                            <p class="mb-0 text-center">Desarrollado por: <span class="text-white font-weight-bold">C & C</span></p>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-center">
                            <a class="social-icons" href="https://www.facebook.com/merengonbavaro91/" target="_blank"><i class="mdi mdi-facebook-box"></i></a>
                            <a class="social-icons" href="https://www.instagram.com/merengonbavaro" target="_blank"><i class="mdi mdi-instagram"></i></a>
                            <a class="social-icons" href="https://wa.me/+573185106016" target="_blank"><i class="mdi mdi-whatsapp"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- End Footer -->
        <!-- Back To Top -->
        <vue-floating/>
        <a href="#" class="back_top"> <i class="mdi mdi-chevron-up"></i></a>
    </div>
</template>
<script>
import VueFloating from "../components/adicionales/Floating.vue"
    export default {
        name: "tomillo_footer",
        methods: {
        },
        components: {
            VueFloating
        }
    }</script>
