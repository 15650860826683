<template>
    <div>
        <!-- Start Preloader -->
        <!-- <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div> -->
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>¿Cómo llegar?</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                        <li class="breadcrumb-item active" aria-current="page">¿Cómo llegar?</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start Get-In-Touch Area -->
        <div class="card blog--card">
            <div class="card-body">
                <p class="texto-como-llegar">¿Conoces el nombre de la vereda en la que se encuentra nuestra casa Merengón Bávaro? Descubre la encantadora Berlín, una de las veredas que es tímidamente mencionada y que tiene los brazos abiertos para recibir a nuevos visitantes.</p>
            </div>
        </div>
        <section class="section como-llegar">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12">
                        <div class="contact_form wow fadeInUp container-iframe" data-wow-delay="0.3s" data-wow-duration="1000ms">
                            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/ypMFBTFpQN8?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="container-iframe">
                            <iframe class="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.9829997858824!2d-75.28290568626336!3d4.475914644814438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38c36e2f0a0f07%3A0x589637cef0b48525!2zTWVyZW5nw7NuIELDoXZhcm8!5e1!3m2!1ses!2sco!4v1675013273469!5m2!1ses!2sco" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row align-items-center texto-como-llegar">
                    <div class="col-md-6 col-sm-12">
                        <br><h3>Transporte público</h3>
                    </div>
                </div>
                <div class="row align-items-center texto-como-llegar">
                    <div class="col-md-6 col-sm-12">
                        <img src="assets/images/como_llegar/ruta_48.png" class="img-fluid" alt="">
                        <br>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <img v-if="screenWidth >= 768" src="assets/images/como_llegar/jeep.png" class="img-fluid" alt="">
                        <img v-else src="assets/images/como_llegar/jeep.png" class="img-fluid" alt="" style="margin-top:10px">
                        <br>
                    </div>
                </div>

                <div class="row align-items-center texto-como-llegar">
                    <div class="col-md-12 col-sm-12">
                        <p>
                            En la avenida quinta de Ibagué puedes tomar la ruta de bus 48, por solo $2.900 pesos colombianos de lunes a sábado y $3.000 domingos y festivos, en puntos como:</p>
                            <ol>
                            <li>Supermercado Surtiplaza Salado</li>
                            <li>Almacén Éxito de la calle 80</li>
                            <li>Centro comercial Multicentro</li>
                            <li>Centro médico Javeriano</li>
                            <li>Iglesia El Carmen</li>
                            <li>Calle 15</li>
                            <li>Parque de la Música</li>
                            <li>Barrio Libertador</li>
                            </ol>
                        <p>
                            En la carrera primera con calle 13 de Ibagué puedes tomar un carro típico o campero y sentir la aventura de recorrer parte del cañón del Combeima.
                        </p>
                    </div>
                    <div class="col-md-12 col-sm-12">
                        <br>
                        <h3>Vehículo particular</h3><br>
                            <img v-if="screenWidth >= 768" src="assets/images/como_llegar/particular.png" alt="Large Image" class="img-fluid">
                            <img v-else src="assets/images/como_llegar/particular_cel.png" alt="Small Image" class="img-fluid">
                        <br><br>
                        <br><h5>Por la carrera primera de Ibagu&eacute;:</h5><br>
                        <p><b>Duraci&oacute;n:</b> 12 min aprox <br>
                        <b>Distancia:</b> 5 km aprox</p>
                        <ol>
                            <li>Toma la carrera primera, pasando por la cl&iacute;nica Tolima y el parque de la m&uacute;sica.</li>
                            <li>Ingresa al barrio Libertador.</li>
                            <li>Sigue la &uacute;nica v&iacute;a hacia el ca&ntilde;&oacute;n del Combeima, que te llevar&aacute; a nuestro punto de venta.</li>
                        </ol>

                        <br><h5>Por la carrera tercera de Ibagu&eacute;:</h5><br>
                        <p><b>Duraci&oacute;n:</b> 12 min aprox <br>
                        <b>Distancia:</b> 5 km aprox</p>
                        <ol>
                            <li>Toma la carrera tercera, pasando por la plaza de Bol&iacute;var.</li>
                            <li>Entra al barrio La Pola, para tomar la calle quinta.</li>
                            <li>Gira a la izquierda, hacia el barrio Libertador.</li>
                            <li>Sigue la &uacute;nica v&iacute;a hacia el ca&ntilde;&oacute;n del Combeima, que te llevar&aacute; a nuestro punto de venta.</li>
                        </ol>

                    </div>
                </div>
            </div>
        </section>
        <!-- End Get-In-Touch Area -->
        <!-- Sección te puede interesar -->
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title">
                            <h4>Te puede interesar</h4>
                            <li><router-link to="/postres">Productos</router-link></li>
                            <li><router-link to="/preguntas">Preguntas frecuentes</router-link></li>
                            <li><router-link to="/historia">Nosotros</router-link></li>
                        </div><br>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Sección te puede interesar -->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //ENLLAX JS
    import '../assets/js/jquery.enllax.min.js';


    export default {
        name: 'contact',
        data() {
            return {
                screenWidth: window.innerWidth
            }
        },
        mounted() {
            this.init();
            window.scrollTo(0, 0);
            window.addEventListener('resize', this.updateScreenSize)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateScreenSize)
        },
        methods: {
            init() {
                this.hidePreloader();
                this.initWowAnimation();
            },
            /*----PRELOADER-----*/
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            updateScreenSize() {
                this.screenWidth = window.innerWidth
            }
        }
    }
</script>
<style scoped>
    .como-llegar {
        padding-bottom: 50px;
        padding-top: 10px;
    }
    .texto-como-llegar {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30px;
    }
    .container-iframe {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
        margin-top: 10px;
    }

    /* Then style the iframe to fit in the container div with full height and width */
    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        }
</style>